import { MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_FUNCTIONS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Function" } }, [_c("text-input", { key: "name", attrs: { "label": "Function", "rules": "required", "span": 24, "disabled": !_vm.can(_vm.permissions.maintain) } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateFunctionModal",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateFunctionModal = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-functions" }, [_c("resource", { attrs: { "page": _vm.page, "name": "sterilization.functions", "api-url": _vm.apiUrl, "create": _vm.CreateFunctionModal, "redirect-route": "/sterilization-specifications/functions" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditFunctions",
  data() {
    return {
      CreateFunctionModal,
      page: STERILIZATION_SPECIFICATIONS_FUNCTIONS,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
